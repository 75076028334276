<template>
  <main class="homeBody">
    <!-- <navbar v-show="isShow" /> -->
    <!-- <float-box /> -->
    <image-box />
    <success-story />
    <advantage-story />
    <consult-center />
    <daily-lives />
    <!-- <index-footer /> -->
  </main>
</template>

<script>
import FloatBox from '../components/floatBox.vue';
import IndexFooter from '../components/IndexFooter.vue';
/* import Navbar from '../components/Navbar.vue'; */
import AdvantageStory from './Home/advantageStory.vue';
import ConsultCenter from './Home/consultCenter.vue';
import DailyLives from './Home/dailyLives.vue';
import SuccessStory from './Home/successStory.vue';
import ImageBox from './Home/ImageBox.vue';

export default {
  components: {
    ImageBox,
    SuccessStory,
    AdvantageStory,
    ConsultCenter,
    DailyLives,
    IndexFooter,
    /* Navbar, */
    FloatBox,
  },
  name: 'Home',
  data() {
    return {
      isShow: true,
    };
  },
  methods: {
    showNav() {
      const that = this;
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
      that.scrollTop = scrollTop;
      if (that.scrollTop > 710) {
        that.isShow = false;
      } else {
        that.isShow = true;
      }
    },
    toBottom() {
      document.body.scrollTop = 10000;
      document.documentElement.scrollTop = 10000;
    },
  },
  mounted() {
    window.addEventListener('scroll', this.showNav);
  },
  destroyed() {
    window.removeEventListener('scroll', this.showNav);
  },
  created() {
    if (this.$route.query.index) {
      this.toBottom();
    }
  },
};
</script>

<style scoped>
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  -moz-box-sizing: border-box; /* Firefox */
  -webkit-box-sizing: border-box; /* Safari */
}

.homeBody {
  width: 100%;
  max-width: 2000px;
  margin: auto;
  box-sizing: border-box;
}

</style>
