<template>
  <!--咨询中心-->
  <div class="consultContainer">
    <index-title-text :text="consultText" :link="link"/>
    <div class="consultItemList">
      <div class="consultItem" v-for="(item, index) in consultItems" :key="index" @click="toNews(item.ID)">
        <div class="consultDate">
          <p class="csD">{{item.Date}}日</p>
          <p class="csYM">{{item.Year}}年{{item.Month}}月</p>
        </div>
        <div class="consultContent">
          <img :src="item.ImgSrc" alt="">
          <p class="title">{{item.Title}}</p>
          <p class="content">{{item.Content}}</p>
          <div class="filter"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import IndexTitleText from '../../components/IndexTitleText.vue';

export default {
  components: { IndexTitleText },
  data() {
    return {
      consultText: '资讯中心',
      link: '/consult',
      consultItems: [
        {
          Date: 3,
          Year:2022,
          Month:6,
          Title: '端午',
          Content: '宝德仕祝您端午安康',
          ImgSrc: require("../../static/img/consultCenterImg/news-6/1.png"),
          ID: 6
        },
        {
          Date: 8,
          Year:2022,
          Month:2,
          Title: '开工大吉',
          Content: '祝愿宝德仕生意兴隆、 红红火火!',
          ImgSrc: require('../../static/img/consultCenterImg/home1.png'),
          ID: 1
        },
        {
          Date: 27,
          Year:2022,
          Month:1,
          Title: '春节快乐',
          Content: '2022新春快乐、 万事如意!',
          ImgSrc: require("../../static/img/consultCenterImg/home2.png"),
          ID: 2
        },
      ],
    };
  },
  methods: {
      toNews(num){
          let path = '/consult/news' + num;
          this.$router.push(path);
      },
  },
};
</script>

<style scoped>
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  transition: all 0.2s;
}

.filter {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -2;
  background: rgba(31, 137, 235, 0.3);
}

.consultContainer {
  width: 100%;
  height: 510px;
  margin-bottom: 100px;
}

.consultItemList {
  width: 90%;
  position: relative;
  top: 25px;
  margin-left: 5%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  grid-column-gap: 50px;
}

.consultItem {
  width: 100%;
  height: 430px;
  cursor: pointer;
}

.consultDate {
  position: relative;
  background-color: #041d72;
  width: 100%;
  height: 160px;
  margin-bottom: 10px;
}

.consultDate > .csD {
  position: absolute;
  top: 32px;
  left: 24px;/*24*/
  font-family: PingFang SC;
  font-style: normal;
  font-size: 40px;
  /* identical to box height */
  color: #ffffff;
  overflow: hidden;
}

.consultDate > .csYM {
  position: absolute;
  top: 85px;
  left: 26px;/*26*/
  font-family: PingFang SC;
  font-style: normal;
  font-weight: lighter;
  font-size: 24px;
  color: #ffffff;
  overflow: hidden;
}

.consultContent {
  padding: 20px 25px;
  width: 100%;
  height: 260px;
  font-family: PingFang SC;
  font-style: normal;
  font-weight: bold;
  color: #000000;
  overflow: hidden;
  text-align: start;
  position: relative;
  z-index: 0;
}

.consultContent:hover{
  color: #ffffff;
}

.consultContent img{
  position: absolute;
  top: 0;
  left: 0;
  width: inherit;
  height: inherit;
  z-index: -3;
}

.title{
  display: block;
  z-index: 3;
  font-size: 36px;
  margin-bottom: 20px;
}

.content{
  display: inline-block;
  z-index: 3;
  font-size: 24px;
}
/*手机端适配*/
@media screen and (max-width: 800px) {
  .consultContainer {
    width: 100%;
    height: 270px;
  }

  .consultItemList {
    width: 90%;
    margin: auto;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 20px;
  }

  .consultItem {
    width: 100%;
    height: 390px;
  }

  .consultDate {
    position: relative;
    background-color: #041d72;
    width: 100%;
    height: 110px;
    margin-bottom: 10px;
  }

  .consultDate > .csD {
    position: absolute;
    top: 16px;
    left: 12px;
    font-family: PingFang SC;
    font-style: normal;
    font-size: 28px;
    /* identical to box height */
    color: #ffffff;
    overflow: hidden;
  }

  .consultDate > .csYM {
    position: absolute;
    top: 60px;
    left: 13px;
    font-family: PingFang SC;
    font-style: normal;
    font-weight: lighter;
    font-size: 18px;
    color: #ffffff;
    overflow: hidden;
  }

  .consultContent {
    padding: 15px 20px;
    width: 100%;
    height: 142px;
  }

  .title{
    display: block;
    z-index: 3;
    font-size: 20px;
    margin-bottom: 15px;
  }

  .content{
    display: inline-block;
    z-index: 3;
    font-size: 8px;
  }
}
</style>
